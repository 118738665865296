export default async function initIndicatorsAndData() {
    const { tagName } = await import('./chart-reset-button');
    const { default: service } = await import(
        './highcharts/highcharts-service'
    );

    service.allowedTags.push(tagName);

    import('./chart');
    import('./chart-provider');
    import('./nowcasting');
}
